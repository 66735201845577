<template>
  <AtomInput :type="passwordShown ? 'text' : 'password'" :interactive-symbol="true">
    <template #symbol>
      <button @click="passwordShown = !passwordShown" type="button">
        <IconEye v-if="passwordShown" />
        <IconEyeOff v-else />
      </button>
    </template>
  </AtomInput>
</template>

<script setup>
import { IconEye, IconEyeOff } from '@tabler/icons-vue'
import AtomInput from '@/components/common/AtomInput'

const passwordShown = ref(false)
</script>
